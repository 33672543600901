@use "@/styles/variables" as *;
@use "@/styles/utils" as *;
@use 'sass:color';

.pill {
    background-color: $color-white;
    border-radius: 3px;
    border: 1px solid $color-gray;
    box-shadow: 0 1px 1px color.change($color-black, $alpha: 0.2);
    color: $color-black;
    display: inline-block;
    font-family: monospace;
    font-size: 0.95em;
    font-weight: bold;
    line-height: 1;
    padding: 2px 4px;
    white-space: nowrap;
    margin: 1.5px;
}
