@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/helpers/content" as *;
@use "@/styles/utils" as *;

// Brand adjustable values
$scroll-effect-font-family: $heading-font !default;
$scroll-effect-font-weight: $font-weight-regular !default;
$scroll-effect-font-size: $font-size-xxxxl !default;
$scroll-effect-font-size-desktop: $font-size-xxxxxl !default;
$scroll-effect-line-height: 1 !default;
$scroll-effect-color: $color-white !default;
$scroll-effect-background: $color-black !default;
$scroll-effect-gradient: linear-gradient($color-shading-900, $color-shading-100, $color-shading-900) !default;
$scroll-effect-max-width: 50rem !default;
$scroll-effect-padding: 3em $space-md !default;

// Outer container for our scroll effect. This serves as the
// scroll distance element, with the content inside it set
// as sticky.
.scrollEffect {
  // Override container margins and sizing, so we can apply a
  // bg color to this element.
  display: block !important;
  margin: 0 !important;
  max-width: none !important;
  height: 250vh;
}

.scrollEffectInnerContainer {
  height: 100vh;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  font-family: $scroll-effect-font-family;
  font-weight: $scroll-effect-font-weight;
  font-size: $scroll-effect-font-size;
  line-height: $scroll-effect-line-height;
  color: $scroll-effect-color;
  background: $scroll-effect-background;
  text-align: center;

  // Override container margins and sizing, so we can apply a
  // bg color to this element.
  margin: 0 !important;
  max-width: none !important;

  // Use the enclosed .block element to apply container styles.
  .block {
    max-width: $scroll-effect-max-width;
    padding: $scroll-effect-padding;
    margin: auto;
    background: $scroll-effect-gradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 100vw 200vh;
    background-position: 0% 0%;
  }

  @include lg-min {
    font-size: $scroll-effect-font-size-desktop;
  }
}

.link {
  display: flex;
  text-decoration: none;
}

.button {
  -webkit-text-fill-color: initial;
  background-clip: initial;
  margin-inline: auto;
  margin-block: 1em;
}
