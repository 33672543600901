@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  @include print {
    page-break-before: always;
  }
}

.infoContainer {
  display: flex;
  flex-direction: column;

  & + .infoContainer {
    margin-top: 26px;
  }

  strong {
    display: block;
    margin-bottom: 6px;
    font-size: $font-size-sm;
  }

  span {
    display: block;
    font-size: $font-size-sm;

    & + span {
      margin-top: 6px;
    }
  }
}
