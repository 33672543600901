@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/helpers/content" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss" with (
  $scroll-effect-font-weight: $font-weight-xthin,
  $scroll-effect-font-size: 60px,
  $scroll-effect-font-size-desktop: 86px,
  $scroll-effect-padding: 1.5em $space-md,
  $scroll-effect-gradient: linear-gradient(#000000 20%, #4A340C 30%, #A1701B 40%, $color-white, #A1701B 60%, #4A340C 70%, #000000 80%),
);
