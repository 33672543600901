@use "@/styles/variables" as *;

.container {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: $space-xxs;
  }

  span {
    margin-top: $space-xxs;
  }
}

.fullWidth {
  width: 100%;
}

.input {
  border: 1px solid $color-gray;
  height: 84px;
  padding: $space-xxs;
  transition: 0.5s all;
  outline: none;
  resize: vertical;

  &:focus {
    border: 1px solid $color-primary;
  }
}

.error {
  textarea {
    border: 1px solid $color-error;
  }

  span {
    color: $color-error;
  }
}
