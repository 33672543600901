@use "@/styles/variables" as *;

.container {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;

  .prompt {
    margin: 0;
  }

  .link {
    text-decoration: none;
  }

  .button {
    margin-top: $space-lg;
    width: 100%;
  }
}
