@use "@/styles/variables" as *;

$color-input-border: $color-light-gray-2 !default;
$icon-size: 16px !default;

.container {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: $space-xxs;
  }

  span {
    margin-top: 2px;
    margin-bottom: 5px;
  }
}

.fullWidth {
  width: 100%;
}

.error {
  .input {
    border: 1px solid $color-error;
  }

  span {
    line-height: 1.25;
    color: $color-error;
  }
}

.labelWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  justify-content: flex-start;
}

// This div was necessary so the eye icon can properly be positioned on the right side of the input
.inputWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 5px;

  input {
    border: 1px solid $color-input-border;
    height: 36px;
    padding: 0 $space-xxs;
    transition: 0.5s all;
    outline: none;
    flex: 1;
    margin-bottom: 0;
    width: 100%;

    &:focus {
      border: 1px solid $color-primary;
    }

    /* Fixes positioning of text-field decoration icons on Safari.

    This solution is a progressive enhancement since `:has` is not supported in all
    browsers targeted by our platform. This is fine as opposed to a comprehensive
    fix because the icon's position on older browsers does not prevent the user
    action; it is simply a minor visual issue. */
    &:has(~ .icon) {
      &::-webkit-credentials-auto-fill-button,
      &::-webkit-caps-lock-indicator,
      &::-webkit-list-button {
        translate: -$icon-size;
      }
    }
  }
}

.icon {
  position: absolute;
  right: $space-xxs;

  width: $icon-size;
  height: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  color: $color-black;
}

.iconEye {
  top: $space-xxxxs;
}

.iconTooltip {
  margin: 0 8px;
}
