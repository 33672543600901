@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.purchaseCard {
  align-self: self-start;
}

.purchaseTitle {
  font-size: $font-size-base;
  text-transform: uppercase;
  margin-bottom: $space-lg;
}

.purchaseText {
  line-height: $line-height-relaxed;
}