@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.accountPage {
  display: flex;
  justify-content: center;
}

.pageWrapper {
  margin: 25px 0;

  width: 100%;
  max-width: $page-content-md-max-width;

  display: flex;
  flex-direction: column;

  @include md-min {
    padding: 0 $space-sm;
  }
}

.breadcrumbsContainer {
  @include lg-min {
    border-bottom: 1px solid $color-gray-2;
    padding-bottom: $space-xxs;
    margin-bottom: $space-xxs;
  }
}

.pageContent {
  align-self: center;

  padding: 0 $space-xxs;

  width: 100%;
  max-width: $page-content-sm-max-width;
}

.pageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0 $space-xxs;

  h1 {
    text-transform: uppercase;
    font-weight: $font-weight-light;
    font-size: $font-size-md;
    line-height: 1;

    margin: $space-xs 0;
  }

  .lock {
    width: $font-size-md;
    height: $font-size-md;
  }

  .skeletonLoader {
    border-radius: 5px;
    height: $font-size-md;
    margin: $space-xs 0;
    width: 200px;
  }
}

.grid {
  width: inherit;
  height: inherit;

  @include md-min {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: auto auto;
    column-gap: $space-xs;
  }
}

.card {
  margin-bottom: 10px;
}

.cardHeader {
  padding: $space-sm $space-xs;
  font-family: $body-font;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;

  @include md-min {
    padding: $space-xs $space-base;
  }
}

.sidebar {
  @include md-min {
    grid-row: 1 / 2;
    grid-column: 2 / 2;
  }
}

.accordionWrapper {
  &:not(:last-child) {
    border-bottom: 1px solid $color-gray-1;
  }
}

.accordionTitle {
  font-family: $body-font;
  font-size: $font-size-sm;

  margin: $space-base 0;

  padding: 0 $space-xs;

  @include md-min {
    padding: 0 $space-base;
  }
}

.linkList {
  width: 100%;
  height: fit-content;
  display: flex;

  flex-direction: column;

  padding: 0 $space-xs;
  padding-bottom: $space-base;

  @include md-min {
    padding: 0 $space-base;
    padding-bottom: $space-base;
  }
}

.link,
.button {
  width: 100%;
  flex: 1;

  font-size: $font-size-sm;
  line-height: 1.5;
  text-transform: none;
}

.button {
  display: inline-flex;

  &:hover {
    text-decoration: underline;
  }
}

.orderHistory {
  @include md-min {
    grid-row: 1 / span 2;
    grid-column: 1 / 1;
  }
}

.logout {
  text-align: left;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
