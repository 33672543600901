@use "@/styles/variables" as *;
@use "@/styles/utils" as *;
@use "../../shared/styles.module.scss" as *;

.accordion {
    overflow-y: auto;
    padding-inline: $alert-inline-padding;

    font-size: $font-size-xs;
    max-block-size: 200px;

    @include md-min {
        font-size: $font-size-sm;
        max-block-size: 300px;
    }
}

.accordionHeader {
    // remove default button styles
    display: block;
    width: 100%;
    text-align: start;
    border: none;

    position: sticky;
    top: 0;

    background-color: $alert-bg-color;

    padding-block: $space-xxs;

    @include md-min {
        padding-block: $space-xs;
    }
}

.accordionTitle {
    display: list-item;
    counter-increment: list-item 0;
    list-style: inside disclosure-closed;

    &.open {
        list-style-type: disclosure-open;
    }
}

.accordionContent {
    padding-bottom: $space-xxs;

    @include md-min {
        padding-bottom: $space-xs;
    }
}

.section {
    margin-block: $space-xxxxs;

    @include md-min {
        margin-block: $space-xxxs;
    }

    &:first-child {
        margin-block-start: 0;
    }
}

.sectionTitle {
    font-weight: $font-weight-bold;
    margin: 0;
    margin-inline-end: 1ch;

    // font-size: $font-size-xs;

    @include md-min {
        font-size: $font-size-sm;
    }
}

.componentChainClamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;

    transition: display 0s 0.1s;
    transition-behavior: allow-discrete;

    &:hover,
    &:focus-within {
        display: block;
    }

    &:hover {
        transition-delay: 0.25s;
    }
}

.hoverHint {
    // font-size: $font-size-xs;
    font-size: 0.9em;
    color: $color-darker-gray;
}

.stackTraceWrapper {
    background-color: $color-white;
    border-radius: $space-xxs;
}

.stackTrace {
    white-space: pre-wrap;
    word-wrap: break-word;
    margin: 0;
    padding: $space-xs;
    font-size: $font-size-xs;

    @include md-min {
        font-size: $font-size-sm;
    }
}

.aggregateDetails {
    display: inline-block;
}

.aggregateDetailsBody {
    margin-block: 0;
    max-height: 100px;
    overflow: auto;
}

.subErrorBtn {
    font-size: inherit;
    text-align: start;
    vertical-align: text-top;
    width: 100%;

    &:hover {
        background-color: #0001;
    }
}
