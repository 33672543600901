@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.returnLabelButton {
  margin-bottom: $space-base;
}

.list {
  padding: 0;
  list-style-position: inside;
  margin: 0;

  li {
    line-height: 21px;
    font-size: $font-size-sm;

    & + li {
      margin-top: $space-xs;
    }
  }
}

.itemList {
  font-weight: $font-weight-bold;

  a {
    color: $color-black-gray;
  }
}

.text {
  margin: 10px 0;
}

.pickupContainer {
  display: flex;
  flex-direction: column;
  margin: 30px 0;

  span {
    display: block;
    margin-bottom: 10px;
  }
}

.noteTitle {
  font-family: $body-font;
  font-size: $font-size-sm;
  margin-bottom: 10px;
}
