@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$font-size-no-reviews-title: 32px;

.container {
  width: 100%;
  padding-top: $space-sm;
}

.loading {
  display: flex;
  justify-content: center;
  padding-top: $space-sm;
}

.header {
  display: flex;
  margin: $space-md 0 $space-xl;
  flex-direction: column;

  @include md-min {
    flex-direction: row;
    justify-content: space-between;
  }
}

.writeReview {
  order: 1;
  margin-bottom: $space-md;

  @include md-min {
    order: 2;
    margin-bottom: 0;
  }
}

.ratingContainer {
  display: flex;
  flex-direction: column;
  gap: $space-xxs;
  order: 2;

  @include md-min {
    order: 1;
  }
}

.starsContainer {
  display: flex;
  align-items: center;
  gap: $space-xxs;
  line-height: 1;
}

.rating {
  font-size: $font-size-xl;
}

.noReviews {
  display: flex;
  flex-direction: column;
  padding: $space-sm $space-sm 0;
  gap: 38px;

  @include md-min {
    justify-content: space-between;
    align-items: flex-end;
    padding: $space-lg $space-lg 0;
    flex-direction: row;
  }
}

.noReviewsTitle {
  text-transform: none;
  margin: 0 0 $space-xxs;
  letter-spacing: 1px;
  font-weight: $font-weight-bold;
  font-size: $font-size-md;

  @include md-min {
    font-size: $font-size-no-reviews-title;
  }
}

.noReviewsText {
  margin: 0;
  max-width: 85%;

  @include md-min {
    max-width: 100%;
  }
}

.modalWrapper {
  padding: $space-xl;
}

.modalBody {
  margin: 0 $space-xs;
}

.productName {
  border-bottom: 3px solid $color-border;
  margin: 0;
  padding: $space-xs 0;
}
