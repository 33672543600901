@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.breadcrumbs {
  display: none;

  @media (min-width: $breakpoint-lg) {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    text-transform: capitalize;
  }
}
