@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$margin: 15px;
$box-shadow: #9b9b9b 0 2px 10px;

.wrapper {
  position: relative;
  width: fit-content;
  height: fit-content;
  z-index: 8;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  > .tooltip {
    position: relative;
    border-radius: 0;

    // Up as default
    transform: translateY(calc(-100% - $margin));
    justify-self: center;
    align-self: flex-start;

    @include slide-in(up);

    // By default, tooltips are non-interactive.
    pointer-events: none;
    height: auto;

    $triangle-size: 36px;
    $triangle-position: $triangle-size;

    .triangle {
      position: absolute;

      overflow: hidden;

      width: $triangle-size;
      height: $triangle-size;

      &::after {
        box-shadow: $box-shadow;

        content: "";
        position: absolute;
        width: $triangle-size / 2;
        height: $triangle-size / 2;
        background: $color-white;
        transform: rotate(45deg);
        bottom: -10px;

        // This value '9' adjusts for the triangle size, but since the triangle
        // is rotated, it's the half of the final computed value.
        left: calc(50% - 9px);
        z-index: 100;
      }
    }

    // -----
    // Position classes
    &.up {
      // Including this class to appease the type-check gods.

      left: 50%;
      transform: translate(-50%, calc(-100% - $margin));
      justify-self: center;
      align-self: flex-start;

      @include slide-in(up);

      .triangle {
        // Up as default
        bottom: $triangle-position * -1;
        right: 50%;
        transform: translateX(-50%);

        rotate: 180deg;
      }
    }

    &.down {
      left: 50%;
      transform: translate(-50%, calc(12% + $margin));
      justify-self: center;
      align-self: flex-end;

      @include slide-in(down);

      .triangle {
        top: $triangle-position * -1;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &.left {
      transform: translateX(calc(-100% - $margin));

      justify-self: left;
      align-self: center;

      @include slide-in(left);

      .triangle {
        top: calc(50% - ($triangle-size / 2));
        right: $triangle-position * -1;

        rotate: 90deg;
      }
    }

    &.right {
      transform: translateX(calc(100% + $margin));

      justify-self: right;
      align-self: center;

      @include slide-in(right);

      .triangle {
        top: calc(50% - ($triangle-size / 2));
        left: $triangle-position * -1;

        rotate: -90deg;
      }
    }

    > .mainContainer {
      display: flex;
      width: max-content;
      height: 100%;

      padding: 15px;
      box-shadow: $box-shadow;

      max-width: min(90vw, 500px);

      &.nowrap {
        max-width: none;
      }

      &.interactive {
        pointer-events: all;
      }

      // -----
      // Variant classes
      &.light {
        background-color: $color-white-gray;
        color: $color-font-base;
      }

      &.dark {
        background-color: $color-black-gray;
        color: $color-white-gray;
      }

      // -----

      > .iconContainer {
        flex: 1;
        width: 100%;
        margin-right: 15px;
        min-width: 30px;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      > .closeButton {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
}

.mainContent {
  flex: 4;
  width: 100%;

  &.nowrap {
    white-space: nowrap;
  }

  > .title {
    font-weight: $font-weight-bold;
    margin-bottom: 10px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  margin-top: 25px;

  a {
    color: $color-black;
  }
}
