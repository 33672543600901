@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  display: flex;
  justify-content: center;
}

.pageWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 $space-sm;
  margin: 0 0 $space-md;
  width: 100%;
  max-width: $page-content-md-max-width;

  @include md-min {
    margin: $space-md 0;
  }
}

.breadcrumbsContainer {
  @include lg-min {
    border-bottom: 1px solid $color-gray-2;
    padding-bottom: $space-xxs;
    margin-bottom: $space-md;
    letter-spacing: 1;
  }
}

.form {
  display: flex;
  margin: 0 auto;
  gap: $space-base;
  flex-direction: column;

  @include md-min {
    flex-direction: row;
  }
}

.fullWidth {
  width: 100%;
}

.halfWidth {
  width: 100%;

  @include md-min {
    width: 50%;
  }
}
