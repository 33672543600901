@use "@/styles/variables" as *;

.container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin: $space-xs 0;
    text-transform: uppercase;
    font-family: $heading-font;
    font-size: $font-size-md;
    letter-spacing: $letter-spacing-headline;
  }

  .icon {
    color: $color-primary;
    width: 25px;
  }
}
