@use "@/styles/variables" as *;

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;

  > .icon {
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    margin: 0;
    color: $color-spinner-icon;
  }
}

.centerWrapper {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.page {
  min-height: 60vh;
}
