@use "@/styles/variables" as *;

.disclaimer {
  margin: 0 0 $space-md;
}

.checkbox {
  margin-top: $space-xs;

  a {
    font-weight: $font-weight-bold;
  }
}

.alert {
  margin-top: $space-base;
}

.checkBalance {
  margin-top: $space-lg;
}
