@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  margin-top: 40px;

  h1 {
    letter-spacing: 1px;
    font-weight: normal;
    text-transform: uppercase;
    font-size: $font-size-xl;
  }
}

.rating {
  display: flex;
  flex-direction: column;
  margin-bottom: $space-sm;
}

.starsWrapper {
  display: flex;
  margin-top: $space-xxxs;
}

.rating input {
  display: none;
}

.inputBlock {
  & + & {
    margin-top: $space-sm;
  }
}

.star {
  height: 26px;
  width: 26px;
  cursor: pointer;
}

.agreement {
  display: block;
  margin-top: $space-sm;
}

.mt16 {
  margin-top: $space-sm;
}
