@use "@/styles/variables" as *;

// Mixins
@mixin base {
  padding: 0;
  font-size: 1rem;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
}

@mixin link {
  background: transparent;
  text-decoration: underline;
  border: 0;
  color: inherit;
}

.text {
  @include base;

  color: inherit;
}

.link {
  @include base;
  @include link;
}

.textUnderline {
  @include base;
  @include link;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
