@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.wrapper {
  border: 1px solid $color-returns-border;
  flex: 1;
  margin-bottom: $space-xs;

  @include md-min {
    margin-bottom: $space-base;
  }
}

.top {
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: $color-returns-header;
  font-weight: $font-weight-bold;
  font-size: $font-size-sm;
  padding: 0 10px;

  @include md-min {
    padding: 0 $space-base;
  }
}

.body {
  background-color: $color-white;
}

.footer {
  border-top: 1px solid $color-returns-border;
  padding: $space-base;
}

.orderContent {
  display: flex;
  padding: $space-base;
}

.productImg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 76px;
  margin-right: $space-xs;
  transition: all 0.5s;
  margin-left: 10px;

  @include lg-min {
    width: 92px;
  }
}

.details {
  display: flex;
  flex-direction: column;
  gap: $space-xxs;
  flex: 1;
}

.delivery {
  margin-top: $space-base;
}

.deliveryInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 18px;
  gap: $space-xxs;
}

.totals {
  display: flex;
  justify-content: space-between;
  margin-bottom: $space-base;
}

.totalItems {
  display: flex;
  flex-direction: column;

  span {
    margin-bottom: $space-xxs;
    font-size: $font-size-sm;
  }

  strong {
    font-size: $font-size-base;
  }
}

.total {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  span {
    margin-bottom: $space-xxs;
    font-size: $font-size-sm;
  }

  strong {
    font-size: $font-size-base;
  }
}

.input {
  label {
    margin-top: $space-sm;
    margin-bottom: $space-xxs;
  }
}

.bottomWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: $space-sm 0 $space-base;
}

.contentWrapper {
  & + & {
    margin-top: 10px;
  }

  strong {
    display: block;
    margin-bottom: 6px;
  }
}

.content {
  display: flex;
  flex-direction: column;

  span {
    margin-bottom: 6px;
  }
}
