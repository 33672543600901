@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.wrapper {
  border: 2px solid $color-returns-border;
  flex: 1;

  @include md-min {
    margin-bottom: $space-base;
  }
}

.top {
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 $space-base;
  background-color: $color-returns-header;
  font-weight: $font-weight-bold;
  font-size: $font-size-sm;
}

.body {
  padding: $space-base;
  background-color: $color-white;
}
