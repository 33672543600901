@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$space-big: 20px;
$space-medium: 15px;
$field-top-margin: 7px;

.input {
  margin-bottom: $field-top-margin;
}

.submitButton {
  margin-top: 8px;
}

.bottomItems {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  @include xs-max {
    label,
    button {
      font-size: 14px;
    }
  }
}

.innerBottomItems {
  display: flex;
  flex-flow: row wrap;

  > * {
    flex-basis: 50%;
    white-space: nowrap;
  }
}

.passwordLink {
  font-weight: $font-weight-bold;
  text-decoration: underline;
}

.spinner {
  width: 14px;
  height: 14px;
}
