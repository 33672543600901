@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.clockwise {
  animation: ease-in-out 0.3s forwards;
  animation-name: clockwise;
}

.counter-clockwise {
  animation: ease-in-out 0.3s forwards;
  animation-name: counter-clockwise;
}

/* Rotation custom prop can be set to adjust behavior of rotation. */
@keyframes clockwise {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(var(--rotation, 45deg));
  }
}

@keyframes counter-clockwise {
  0% {
    transform: rotate(var(--rotation, 45deg));
  }

  100% {
    transform: rotate(0deg);
  }
}

.interactive {
  cursor: pointer;
}

.wrapper > svg {
  display: inline-block;
  fill: currentColor;
}

.size-xxs {
  width: 10px;
}

.size-xs {
  width: 12px;
}

.size-sm {
  width: 14px;
}

.size-heavy {
  width: 18px;
}

.size-1x {
  width: 16px;
}

.size-2x {
  width: 32px;
}

.size-3x {
  width: 48px;
}

.size-lg {
  width: 20px;
}

.size-xl {
  width: 24px;
}
